const CU_MAX = 864;
const custom_client = {
    Editpane: {
        methods: {
            validateMux(data) {
                return new Promise((resolve, reject) => {
                    if ((data.item.services_cu?.cu || 0) > CU_MAX) {
                        alert('The combined CU of the services exceeds the maximum of ' + CU_MAX);
                        reject();
                    }
                    resolve(data);
                })
            }
        },
    },
};

export default custom_client;
